import React, { Component } from "react";
import "./Timer.css";

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.count = this.count.bind(this);
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0,
      time_up: ""
    };
    this.x = null;
    this.deadline = null;
  }

  count() {
    const now = new Date().getTime();
    const t = this.deadline - now;

    if (t > 0) {
      const days = Math.floor(t / (1000 * 60 * 60 * 24));
      const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((t % (1000 * 60)) / 1000);

      this.setState({
        days: days < 10 ? "0" + days : days,
        hours: hours < 10 ? "0" + hours : hours,
        minutes: minutes < 10 ? "0" + minutes : minutes,
        seconds: seconds < 10 ? "0" + seconds : seconds
      });
    } else {
      clearInterval(this.x);
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        time_up: "TIME IS UP"
      });
    }
  }

  componentDidMount() {
    const currentDate = new Date();
    this.deadline = new Date(currentDate.setMonth(currentDate.getMonth() + 1, currentDate.getDate() + 15)).getTime();

    this.count();

    this.x = setInterval(this.count, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.x);
  }

  render() {
    const { days, seconds, hours, minutes, time_up } = this.state;
    return (
        <div id="countdown">
          <div className="col-4">
            <div className="box">
              <p id="day">{days}</p>
              <span className="text">Days</span>
            </div>
          </div>
          <div className="col-4">
            <div className="box">
              <p id="hour">{hours}</p>
              <span className="text">Hours</span>
            </div>
          </div>
          <div className="col-4">
            <div className="box">
              <p id="minute">{minutes}</p>
              <span className="text">Minutes</span>
            </div>
          </div>
          <div className="col-4">
            <div className="box">
              <p id="second">{seconds}</p>
              <span className="text">Seconds</span>
            </div>
          </div>
          {time_up && <div className="time-up">{time_up}</div>}
        </div>
    );
  }
}

export default CountDown;
