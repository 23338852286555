import React from "react";
import ReactDOM from "react-dom";
import Timer from "./components/Countdown/Timer";

import "./styles.css";

function App() {
    return (
        <div className="App">
            <div className="container">
                <h1>
                    eKonobar
                </h1>
                <h2>
                    Coming Soon
                </h2>
                <Timer/>
            </div>
        </div>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App/>, rootElement);
